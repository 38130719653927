import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IAdHocLine} from 'app/blocks/model/ad-hoc-line';
import {IPurchaseInvoiceItem} from 'app/blocks/model/purchase-invoice-item.model';
import {IDiscount} from 'app/blocks/model/discount.model';
import {Mposfile} from 'app/blocks/model/mpos-file.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IPurchaseInvoice extends IBaseEntity {
    id?: number;
    purchaseOrder?: string;
    supplier?: any;
    purchaseOrderValue?: number;
    invoiceNo?: string;
    invoiceDate?: any;
    invoiceValue?: number;
    invoiceDueDate?: any;
    receiptAvailable?: boolean;
    purchaseOrderId?: number;
    purchaseInvoiceStatus?: string;
    underDispute?: boolean;
    underDisputeNote?: string;
    purchaseInvoiceItems?: IPurchaseInvoiceItem[];
    adHocLineItems?: IAdHocLine[];
    subTotal?: any;
    total?: any;
    tax?: any;
    discount?: any;
    inventoryMovements?: any;
    receipt?: Mposfile;
    paid?: number;
    balance?: number;
    carriedBalance?: number;
    paymentStatus?: string;
    purchasePaymentAllocations?: any[];
    purchaseCreditNoteAllocations?: any[];
}

export class PurchaseInvoice extends BaseEntity implements IPurchaseInvoice {
    public id?: number;
    public purchaseOrder?: string;
    public supplier?: any;
    public purchaseOrderValue?: number;
    public invoiceNo?: string;
    public invoiceDate?: any;
    public invoiceValue?: number;
    public invoiceDueDate?: any;
    public receiptAvailable?: boolean;
    public purchaseOrderId?: number;
    public purchaseInvoiceStatus?: string;
    public underDispute?: boolean;
    public underDisputeNote?: string;
    public purchaseInvoiceItems?: IPurchaseInvoiceItem[];
    public adHocLineItems?: IAdHocLine[];
    public subTotal?: any;
    public total?: any;
    public tax?: any;
    public discount?: IDiscount;
    public inventoryMovements?: any;
    public receipt?: Mposfile;
    paid?: number;
    balance?: number;
    carriedBalance?: number;
    paymentStatus?: string;
    purchasePaymentAllocations?: any[];
    purchaseCreditNoteAllocations?: any[];

    constructor(purchaseInvoice?: any) {
        super();
        if (purchaseInvoice) {
            this.id = purchaseInvoice.id;
            this.purchaseOrder = purchaseInvoice.purchaseOrder;
            this.supplier = purchaseInvoice.supplier;
            this.purchaseOrderValue = purchaseInvoice.value;
            this.invoiceNo = purchaseInvoice.invoiceNo;
            this.invoiceDueDate = purchaseInvoice.invoiceDueDate;
            this.invoiceValue = purchaseInvoice.invoiceValue;
            this.invoiceDate = purchaseInvoice.invoiceDate;
            this.receiptAvailable = purchaseInvoice.receiptAvailable;
            this.purchaseOrderId = purchaseInvoice.purchaseOrderId;
            this.purchaseInvoiceStatus = purchaseInvoice.purchaseInvoiceStatus;
            this.underDispute = purchaseInvoice.underDispute;
            this.underDisputeNote = purchaseInvoice.underDisputeNote;
            this.purchaseInvoiceItems = purchaseInvoice.purchaseInvoiceItems;
            this.adHocLineItems = purchaseInvoice.adHocLineItems;
            this.subTotal = purchaseInvoice.subTotal;
            this.tax = purchaseInvoice.tax;
            this.total = purchaseInvoice.total;
            this.discount = purchaseInvoice.discount;
            this.inventoryMovements = purchaseInvoice.inventoryMovements;
            this.receipt = purchaseInvoice.receipt;
            this.paid = purchaseInvoice.paid;
            this.balance = purchaseInvoice.balance;
            this.carriedBalance = purchaseInvoice.carriedBalance;
            this.paymentStatus = purchaseInvoice.paymentStatus;
            this.purchasePaymentAllocations = purchaseInvoice.purchasePaymentAllocations;
            this.purchaseCreditNoteAllocations = purchaseInvoice.purchaseCreditNoteAllocations;
        } else {
            this.paymentStatus = 'UNPAID';
            this.invoiceDueDate = MposDate.currentDate();
            this.purchaseInvoiceItems = [];
            this.adHocLineItems = [];
            this.purchasePaymentAllocations = [];
            this.purchaseCreditNoteAllocations = [];
            this.subTotal = 0;
            this.total = 0;
            this.tax = 0;
        }
    }

    get discriminator(): any {
        return this.invoiceNo;
    }

    clone(): IBaseEntity {
        return new PurchaseInvoice(this);
    }
}
