import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IPurchaseInvoice, PurchaseInvoice} from 'app/blocks/model/purchase-invoice.model';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class PurchaseInvoiceService extends EntityCrudService<IPurchaseInvoice> {
    constructor(http: HttpClient) {
        super(http, Resource.PURCHASE_INVOICE, (obj?) => new PurchaseInvoice(obj));
    }

    createPurchaseInvoiceWithGivenItemEntries(data: any): Promise<any> {
        const REQUEST_URL = `${SERVER_API_URL}api/purchase-invoice`;
        return this.http.post(REQUEST_URL, data).toPromise();
    }

    uploadFile = (label: string, file: File, purchaseInvoiceId: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('label', label);
        return this.http.post<any>(`${SERVER_API_URL}api/purchase-invoice/${purchaseInvoiceId}/docs`, fd).toPromise();
    };

    downloadFile = (purchaseInvoiceId: number, preview = false): any => {
        const type = preview === true ? 'blob' : 'arraybuffer';
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-invoice/${purchaseInvoiceId}/docs`;
        return this.http.get(REQUEST_URI, {responseType: type as 'json'});
    };

    downloadMergedPurchaseInvoices = (ids: number[]): Promise<any> => {
        const requestParams = createRequestOption({ids: ids});
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-invoices/download-PDF`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    getUnpaidPurchaseInvoiceForSupplier = (supplierId: number, sortParam: any): any => {
        const requestParams = createRequestOption(sortParam);
        return this.http
            .get(SERVER_API_URL + 'api/suppliers/' + supplierId + '/unpaid-purchase-invoices', {params: requestParams, observe: 'response'})
            .toPromise();
    };
}
